<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="pressaoIntraocular"
  >
    <b-row>
      <b-col v-if="canEdit && form.value.length <= 1">
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="4">
                <div class="form-group mb-0">
                  <label for="pio-aparelho">Aparelho de medição</label>
                  <multiselect
                    v-model="fields.aparelho"
                    id="pio-aparelho"
                    :disabled="!canEdit"
                    :options="['Tonômetro de sopro', 'Aplanação', 'iCare', 'Tonopen']"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border mb-0"
                    @select="onSelect()"
                  >
                    <template slot="caret">
                      <div class="new-medical-report">
                        <Close v-if="fields.aparelho" class="icon close" @click="clearFields" />
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                </div>
              </b-col>
              <b-col cols="2">
                <div class="form-group mb-0">
                  <label for="pio-horario">Horário</label>
                  <input
                    autocomplete="off"
                    v-model="fields.horario"
                    :readonly="!canEdit || !fields.aparelho"
                    @input="updateForm()"
                    id="pio-horario"
                    type="text"
                    class="form-control"
                    placeholder="00:00"
                    v-mask="'##:##'"
                  />
                </div>
              </b-col>
              <b-col cols="3">
                <div class="form-group mb-0">
                  <label for="pio-direito">Olho direito</label>
                  <div class="input-eye">
                    <p class="tag-eye"><EyeFill /> D</p>
                    <input
                      autocomplete="off"
                      v-model="fields.olhoDireito"
                      :readonly="!canEdit || !fields.aparelho"
                      @input="updateForm()"
                      id="pio-direito"
                      type="text"
                      class="form-control"
                      placeholder="Resultado"
                    />
                  </div>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="form-group mb-0">
                  <label for="pio-esquerdo">Olho esquerdo</label>
                  <div class="input-eye">
                    <p class="tag-eye"><EyeFill /> E</p>
                    <input
                      autocomplete="off"
                      v-model="fields.olhoEsquerdo"
                      :readonly="!canEdit || !fields.aparelho"
                      @input="updateForm()"
                      id="pio-esquerdo"
                      type="text"
                      class="form-control"
                      placeholder="Resultado"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="tonometria" v-if="fields.tonometria">
          <b-col cols="4">
            <div class="form-group mb-0 tonometria-tool">
              <multiselect
                v-model="fields.tonometria.aparelho"
                id="pio-aparelho-tonometria"
                :options="['Tonômetro de sopro']"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                :allowEmpty="false"
                placeholder="Selecionar"
                class="with-border disabled"
                disabled
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group mb-0">
              <input
                autocomplete="off"
                v-model="fields.tonometria.horario"
                id="pio-horario-tonometria"
                type="text"
                class="form-control"
                placeholder="00:00"
                v-mask="'##:##'"
                readonly
                />
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> D</p>
                <input
                  autocomplete="off"
                  v-model="fields.tonometria.olhoDireito"
                  id="pio-direito-tonometria"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                  readonly
                />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> E</p>
                <input
                  autocomplete="off"
                  v-model="fields.tonometria.olhoEsquerdo"
                  id="pio-esquerdo-tonometria"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                  readonly
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-else cols="12" v-for="(value, index) of form.value" :key="index">
        <b-row>
          <b-col>
            <b-row class="tonometria">
              <b-col cols="4">
                <div class="form-group mb-0">
                  <label v-if="!index" for="pio-aparelho">Aparelho de medição</label>
                  <multiselect
                    v-model="value.aparelho"
                    id="pio-aparelho"
                    :disabled="!canEdit"
                    :options="['Tonômetro de sopro', 'Aplanação', 'iCare', 'Tonopen']"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border mb-0"
                    @select="el => debounceInput('aparelho', el.target.value, index)"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                </div>
              </b-col>
              <b-col cols="2">
                <div class="form-group mb-0">
                  <label v-if="!index" for="pio-horario">Horário</label>
                  <input
                    autocomplete="off"
                    v-model="value.horario"
                    @input="el => debounceInput('horario', el.target.value, index)"
                    :readonly="!canEdit"
                    id="pio-horario"
                    type="text"
                    class="form-control"
                    placeholder="00:00"
                    v-mask="'##:##'"
                  />
                </div>
              </b-col>
              <b-col cols="3">
                <div class="form-group mb-0">
                  <label v-if="!index" for="pio-direito">Olho direito</label>
                  <div class="input-eye">
                    <p class="tag-eye"><EyeFill /> D</p>
                    <input
                      autocomplete="off"
                      v-model="value.olhoDireito"
                      @input="el => debounceInput('olhoDireito', el.target.value, index)"
                      :readonly="!canEdit"
                      id="pio-direito"
                      type="text"
                      class="form-control"
                      :placeholder="index === 0 ? getFirstPlaceholder :  getSecondPlaceholder"
                    />
                  </div>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="form-group mb-0">
                  <label v-if="!index" for="pio-esquerdo">Olho esquerdo</label>
                  <div class="input-eye">
                    <p class="tag-eye"><EyeFill /> E</p>
                    <input
                      autocomplete="off"
                      v-model="value.olhoEsquerdo"
                      @input="el => debounceInput('olhoEsquerdo', el.target.value, index)"
                      :readonly="!canEdit"
                      id="pio-esquerdo"
                      type="text"
                      class="form-control"
                      :placeholder="index === 0 ? getFirstPlaceholder :  getSecondPlaceholder"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="tonometria" v-if="value.tonometria">
          <b-col cols="4">
            <div class="form-group mb-0 tonometria-tool">
              <multiselect
                v-model="value.tonometria.aparelho"
                id="pio-aparelho-tonometria"
                :options="['Tonômetro de sopro']"
                :option-height="40"
                :showLabels="false"
                :searchable="false"
                :allowEmpty="false"
                placeholder="Selecionar"
                class="with-border disabled"
                disabled
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="form-group mb-0">
              <input
                autocomplete="off"
                v-model="value.tonometria.horario"
                id="pio-horario-tonometria"
                type="text"
                class="form-control"
                placeholder="00:00"
                v-mask="'##:##'"
                readonly
                />
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> D</p>
                <input
                  autocomplete="off"
                  v-model="value.tonometria.olhoDireito"
                  id="pio-direito-tonometria"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                  readonly
                />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> E</p>
                <input
                  autocomplete="off"
                  v-model="value.tonometria.olhoEsquerdo"
                  id="pio-esquerdo-tonometria"
                  type="text"
                  class="form-control"
                  placeholder="Resultado"
                  readonly
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <button
        class="btn blue-underline button-copy center mt"
        @click="copyEyeValues()"
        v-if="canEdit && form.value.length <= 1"
        v-b-tooltip.hover title="Copiar valores O.D para O.E"
      >
        <Copy />
      </button>
    </b-row>
  </FormWrapper>
</template>

<script>
import { track } from '@/utils/mixPanel'
import { mapState, mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    Copy: () => import('@/assets/icons/copy.svg'),
    Close: () => import ('@/assets/icons/close.svg'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.pressaoIntraocular,
      selectedForm: state => state.attendance.form.selected,
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation']),
    getFirstPlaceholder() {
      if(this.selectedForm?.label !== 'Programa Glaucoma SUS') {
        return 'Resultado'
      }
      return 'Result. com med.'
    },
    getSecondPlaceholder() {
      if(this.selectedForm?.label !== 'Programa Glaucoma SUS') {
        return 'Resultado'
      }
      return 'Result. sem med.'
    }
  },
  data() {
    return {
      pressaoIntraocular: [],
      fields: {},
      debounceInput: debounce(this.updateMultiple, 300)
    }
  },
  mounted() {
    this.setTonometria()
  },
  watch: {
    hasPreConsultation: function(){
      this.setTonometria()
    },
    canEdit: function(){
      this.setTonometria()
    },
    'form.showing': function(){
      this.setTonometria()
    },
    'form.value': {
      handler(value) {
        if (!value?.length) {
          this.fields = this.getFields()
          return
        }
        const tonometria = this.preConsultation?.medical_records?.find(el => el.type === 'tonometria')
        if (tonometria) {
          this.fields.tonometria = tonometria.value
        }
        this.fields = value[0]
      },
      immediate: true,
      deep: true
    },
    selectedForm: {
      immediate: true,
      deep: true,
      handler() {
        if(this.selectedForm?.label === 'Programa Glaucoma SUS'){
          const value = [
            {
              aparelho: this.fields?.aparelho,
              horario: this.fields?.horario,
              olhoDireito: this.fields?.olhoDireito,
              olhoEsquerdo: this.fields?.olhoEsquerdo
            },
            {
              aparelho: null,
              horario: null,
              olhoDireito: null,
              olhoEsquerdo: null
            },
          ]
          this.handleProps({ key: 'value', value: value })
        }
      }
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/pressaoIntraocular', ['handleFields','addValue', 'handleProps', 'handleTonometria']),
    updateProps(value) {
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    setTonometria() {
      const value = [...this.form.value]

      if (!this.hasPreConsultation || !this.canEdit) return;
      const tonometria = this.preConsultation.medical_records.find(el => { return el.type === 'tonometria' })

      if (!tonometria || (!tonometria?.value?.olhoDireito || !tonometria?.value?.olhoDireito )) 
        return;
      
      this.fields.tonometria = tonometria.value
      value.push(this.fields)
      this.updateForm()
    },
    getFields() {
      return {
        aparelho: null,
        horario: null,
        olhoDireito: null,
        olhoEsquerdo: null,
        tonometria: null,
      }
    },
    updateMultiple(key, value, index){
      this.form.value[index][key] = value
      this.handleProps({ key: 'value', value: this.form.value })
      this.updateMedicalRecord(this.form)
    },
    updateForm() {
      const value = [...this.form.value]
      
      if(!value.length){      
        value.push(this.fields)
      }
      else{
        value[0] = this.fields
      }
      this.updateProps(value) 
    },
    copyEyeValues() {
      this.form.value[0].olhoEsquerdo = this.form.value[0].olhoDireito
      this.updateForm()
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
            track('copy_eye_values', {
          'module': 'Pressao Intraocular'
      });
    },
    onSelect() {
      this.fields.horario = this.moment().format('HH:mm')
      this.updateForm()
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      if(!this.form.fields){
        this.form.fields = []
      } else {
        Object.keys(this.form.fields).forEach(key => {
          this.form.fields[key] = null
        });
      }
    },
    clearFields() {
      this.fields.aparelho = null
      this.fields.horario = null
      this.fields.olhoDireito = null
      this.fields.olhoEsquerdo = null
      this.updateMedicalRecord(this.form)
    },
  },
}
</script>
<style lang="scss" scoped>
.form-group {

  font-weight: 600;
  font-size: 14px;
  color: var(--blue-700);
  margin-bottom: 4px !important;
}
  .new-medical-report {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    top: 0;
    right: 10px;
    z-index: 10;
    gap: 10px;
    .icon {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
      cursor: pointer;
      &.trash {
        fill: none;
        stroke: var(--states-red-soft);
      }
      &.edit {
        fill: none;
        stroke: var(--blue-500);
      }
      &.close {
        fill: var(--type-active);
      }
    }
  }
.icon {
  width: 24px;
  height: 24px;
  fill: var(--blue-500);
  margin: 4px 12px 0px 4px;
  cursor: pointer;
}
</style>